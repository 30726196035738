var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$attrs.link)?_c('span',[_c('a',{attrs:{"href":"javascript:void(0)","title":_vm.$t('copy', {
        item: `${_vm.info.type ? _vm.$tc(_vm.info.type, 1).toLowerCase() + ':' : ''} ${
          _vm.info.path
        }`
      })},on:{"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();$event.stopPropagation();return _vm.$utils.toClipboard(_vm.$attrs.link == 'js' ? _vm.info.js : _vm.info.path)}}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.$tc(_vm.$attrs.title || "titles.resource_path", 1))+" "),_c('i',{staticClass:"fa fa-copy"})]})],2)]):_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$tc(_vm.$attrs.title || "titles.resource_path", 1))+" "),_c('ToolTip',{attrs:{"title":_vm.$t('hints.resource_path')}})],1),_c('div',{staticClass:"input-group"},[(_vm.info.type)?_c('div',{staticClass:"input-group-addon disabled",attrs:{"title":_vm.info.type}},[_vm._v(" "+_vm._s(_vm.$tc(_vm.info.type, 1))+" ")]):_vm._e(),_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.info.path}}),_c('div',{staticClass:"input-group-addon btn",attrs:{"title":_vm.$t('copy', {
          item: `${_vm.info.type ? _vm.$tc(_vm.info.type, 1).toLowerCase() + ':' : ''} ${
            _vm.info.path
          }`
        })},on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();$event.stopPropagation();return _vm.$utils.toClipboard(_vm.info.path)},function($event){if($event.shiftKey||$event.altKey||$event.metaKey)return null;if(!$event.ctrlKey)return null;$event.preventDefault();$event.stopPropagation();return _vm.$utils.toClipboard(_vm.info.js)}]}},[_c('i',{staticClass:"fa fa-copy"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }