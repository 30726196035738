<template>
  <span v-if="$attrs.link">
    <a
      href="javascript:void(0)"
      @click.exact.prevent.stop="
        $utils.toClipboard($attrs.link == 'js' ? info.js : info.path)
      "
      v-bind:title="
        $t('copy', {
          item: `${info.type ? $tc(info.type, 1).toLowerCase() + ':' : ''} ${
            info.path
          }`
        })
      "
    >
      <slot name="label">
        {{ $tc($attrs.title || "titles.resource_path", 1) }}
        <i class="fa fa-copy"></i>
      </slot>
    </a>
  </span>
  <div class="form-group" v-else>
    <label for=""
      >{{ $tc($attrs.title || "titles.resource_path", 1) }}
      <ToolTip :title="$t('hints.resource_path')" />
    </label>
    <div class="input-group">
      <div
        v-if="info.type"
        class="input-group-addon disabled"
        :title="info.type"
      >
        {{ $tc(info.type, 1) }}
      </div>
      <input type="text" class="form-control" disabled :value="info.path" />
      <div
        class="input-group-addon btn"
        v-bind:title="
          $t('copy', {
            item: `${info.type ? $tc(info.type, 1).toLowerCase() + ':' : ''} ${
              info.path
            }`
          })
        "
        @click.exact.prevent.stop="$utils.toClipboard(info.path)"
        @click.exact.ctrl.prevent.stop="$utils.toClipboard(info.js)"
      >
        <i class="fa fa-copy"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";

export default {
  name: "ResourceField",
  components: {
    ToolTip
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    info() {
      return this.$root.$formatter.info(this.value);
    }
  }
};
</script>

<style scoped>
.input-group-addon.disabled {
  background-color: #fafafa;
  font-weight: 666;
}

/* a > i.fa-copy {
  color: transparent;
}

a:hover > i.fa-copy {
  color: inherit;
} */
</style>
