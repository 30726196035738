<template>
  <section v-if="isReady">
    <div class="container" v-if="$can('manage', 'DadoEscrita')">
      <EquipmentDataPanel
        :equipment="equipment"
        :display="display"
        :panelName="panelName"
        :panel="panel"
        :refreshTimerEnabled="true"
      >
      </EquipmentDataPanel>
    </div>
    <InfoBox v-else preset="unauthorized" />
  </section>
</template>

<script>
import EquipmentDashboardBase from "@/views/private/DashboardEquipmentBase.vue";
import Panels from "@/assets/dashboard/panels.json";
import EquipmentDataPanel from "@/components/equipment-data-panel.vue";
import InfoBox from "@/components/info-box.vue";
import { mqttTopic } from "@/services/equipment.js";
import { isEqual, debounce } from "lodash";
export default {
  name: "DashboardDataEditor",
  extends: EquipmentDashboardBase,
  components: {
    EquipmentDataPanel,
    InfoBox
  },
  computed: {
    panel() {
      // get the screen panel
      var panel = null;
      if ((this?.display?.panels || [])?.length) {
        panel = this.display.panels.find(
          ({ template }) => template == "EquipmentDataPanel"
        );
      }
      if (!panel) {
        let cfg = Panels.find(
          (item) => item.template.template == "EquipmentDataPanel"
        );
        if (cfg && cfg.template) {
          panel = JSON.parse(JSON.stringify(cfg.template));
        }
      }
      return panel;
    }
  },
  watch: {
    templateState(n, o) {
      if (o == "loading" && n == "ready") {
        this.setDisplay();
      }
    },
    equipment: {
      handler(n, o) {
        if (n && !o) {
          this.parseMQTTConnectorTopics();
          if (this.mqttConnectorTopics) {
            this.onMQTTStatus(this.$http.options.mqtt.status);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    fetchEquipmentDataList() {
      var equipmentId = this?.equipment?.id || this.equipmentId;
      if (!equipmentId) return;
      var query = {
        resource: "data",
        connectorId: equipmentId,
        forceUpdate: true
      };
      this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    onMQTTStatus(status) {
      if (status == "CONNECTED") {
        this.$http.options.mqtt.subscribeOnlyOnTopics(
          Object.keys(this.mqttConnectorTopics || {}).map((t) => `${t}/#`)
        );
      }
    },
    enableMQTT(option) {
      if (option) {
        if (this.onMQTTStatus) {
          this._onMQTTStatus = ($e) =>
            this.onMQTTStatus && this.onMQTTStatus($e.detail);
          this.$rt.addListener("mqtt:status", this._onMQTTStatus);
        }
        if (this.onMQTTMessage) {
          this._onMQTTMessage = ($e) =>
            this.onMQTTMessage && this.onMQTTMessage($e.detail);
          this.$rt.addListener("mqtt:message", this._onMQTTMessage);
        }
      } else {
        if (this._onMQTTStatus)
          this.$rt.removeListener("mqtt:status", this._onMQTTStatus);
        if (this._onMQTTMessage)
          this.$rt.removeListener("mqtt:message", this._onMQTTMessage);
      }
    },
    isMQTTAvailable(connector) {
      return (
        connector &&
        (connector?.protocol?.is_mqtt_protocol ||
          this?.$root?.config?.mqtt?.modbus_enabled) &&
        connector.mqtt_topic_prefix
      );
    },
    parseMQTTConnectorTopics() {
      this.mqttConnectorTopics = null; // non reactive
      if (
        this.dashboardMode == "editor" ||
        this.mode == "editor" ||
        !(
          this.$root.$http.options.config?.mqtt?.websocket?.host &&
          this.$root.$http.options.config?.mqtt?.websocket?.port &&
          (this.$root.$http.options.config?.mqtt?.enabled ?? true)
        )
      )
        return;
      let topics = {};
      let instanceIds = [];
      if (this.isMQTTAvailable(this.equipment)) {
        instanceIds.push(parseInt(this?.equipment.id));
      }
      instanceIds = this.$utils.distinct(
        instanceIds.concat(
          Object.values(this?.source?.models || {}).reduce(
            (p, c) => p.concat((c || []).map((id) => parseInt(id))),
            instanceIds
          )
        )
      );
      this.connectorList
        .filter(
          ({ id, mqtt_topic_prefix }) =>
            instanceIds.indexOf(parseInt(id)) >= 0 && mqtt_topic_prefix
        )
        .forEach(({ id, mqtt_topic_prefix }) => {
          topics[mqtt_topic_prefix] = id;
        });

      this.mqttConnectorTopics = Object.keys(topics).length ? topics : null;
    },
    onMQTTMessage($event) {
      if (this.$http.options.mqtt.status !== "CONNECTED") return;
      let entry;
      let topic = $event.info.destinationName.replace(
        /\/(connector_state|alarm_state|completed_data_acquisition|completed_data_write_cycle)/g,
        ""
      );
      let data = (this.dataList || []).find((i) => mqttTopic(i) == topic);
      let connector = this.connectorList.find(
        ({ mqtt_topic_prefix }) => mqtt_topic_prefix == topic
      );
      if (data) {
        // console.log(`${topic} ${JSON.stringify(msg)}`);
        if (/alarm_state/.test($event.info.destinationName)) {
          entry = {
            id: $event.msg.id,
            ...$event.msg
          };
          entry.last_transition_at = new Date(
            $event.msg?.last_transition_at || null
          ).toISOString();
          this.$store.dispatch("dashboard/setAlarmValue", entry);
        } else {
          // console.log(data);
          entry = {
            id: data.id,
            value: $event.msg.value
          };
          if ($event.msg?.timestamp) {
            entry.date_time = new Date($event.msg?.timestamp).toISOString();
            entry.restore = {
              id: data.id,
              date_time: entry.date_time,
              value: entry.value
            };
          }
          this.$store.commit("dashboard/SET_DATA_VALUE", entry);
        }
      } else if (/connector_state/.test($event.info.destinationName)) {
        if (connector) {
          entry = {
            id: connector.id,
            ...$event.msg
          };
          this.$store.dispatch("dashboard/setConnectorValue", entry);
        }
      } else if (
        /completed_data_acquisition/.test($event.info.destinationName)
      ) {
        // it is only valid for modbus connectors
        if (!connector || connector?.protocol?.is_mqtt_protocol) return;
        // it skips connection status - since it will be handle by a different topic
        // console.log("completed_data_acquisition");
        this.onDataArquisition(connector.id);
      } else if (
        /completed_data_write_cycle/.test($event.info.destinationName)
      ) {
        // it is only valid for modbus connectors
        if (!connector || connector?.protocol?.is_mqtt_protocol) return;
        // console.log("completed_data_write_cycle");
        this.$root.$emit("data:sync");
      }
    }
  },
  beforeCreate() {
    this._onMQTTStatus = ($e) =>
      this.onMQTTStatus && this.onMQTTStatus($e.detail);
    this._onMQTTMessage = ($e) =>
      this.onMQTTMessage && this.onMQTTMessage($e.detail);
    document.addEventListener("mqtt:status", this._onMQTTStatus);
    document.addEventListener("mqtt:message", this._onMQTTMessage);
    this.onDataArquisition = debounce((connectorId) => {
      this.refreshDashboard(connectorId, true);
    }, 200);
  },
  created() {
    this.fetchEquipmentDataList();
  },
  beforeDestroy() {
    document.removeEventListener("mqtt:status", this._onMQTTStatus);
    document.removeEventListener("mqtt:message", this._onMQTTMessage);
  }
};
</script>

<style scoped>
.nav-tabs-custom > .tab-content {
  max-height: 100% !important;
}
</style>
